import React, { PropsWithChildren, useEffect, useState } from 'react';
import { isEmpty, isEqual, uniqueId } from 'lodash';
import { useBasketFamilyQuantitiesQuery } from 'api/basket';
import {
	FavouriteListLineProductKeyModel,
	ProductItemResponse,
	ProductMasterItemResponse,
	ProductOrderDetails,
	ProductOrderType,
	SplashResponse,
	SplashType,
} from 'generated/data-contracts';

interface ProductCardBottomSplashes {
	quantityInBasket?: number;
	inbound?: ProductOrderDetails;
	delivered?: ProductOrderDetails;
	fashionForward?: SplashResponse;
}

interface UseProductCardContextReturnType {
	key: string;
	product: ProductItemResponse;
	activeMaster: ProductMasterItemResponse | undefined;
	setActiveMaster: React.Dispatch<React.SetStateAction<ProductMasterItemResponse | undefined>>;
	favouriteCases: FavouriteListLineProductKeyModel[];
	setFavouriteCases: React.Dispatch<React.SetStateAction<FavouriteListLineProductKeyModel[]>>;
	isBeingHovered: boolean;
	setIsBeingHovered: React.Dispatch<React.SetStateAction<boolean>>;
	isSkeleton: boolean;
	isHidden: boolean;
	showRemoveIcon: boolean;
	hideFavIcon: boolean;
	bottomSplashes: ProductCardBottomSplashes;
	updateNavParams: () => void;
	className?: string;
	renderedOnFavouriteList?: boolean;
}

interface ProductCardContextContextProps {
	product?: ProductItemResponse;
	isSkeleton?: boolean;
	isHidden?: boolean;
	showRemoveIcon?: boolean;
	className?: string;
	hideFavIcon?: boolean;
	renderedOnFavouriteList?: boolean;
	updateNavParams?: () => void;
}

const useGetProductCardBottomSplashes = (product: ProductItemResponse): ProductCardBottomSplashes => {
	const { data: familyQuantities } = useBasketFamilyQuantitiesQuery();

	let quantityInBasket;

	const fashionForward = product.splashes?.find((splash) => splash.type === SplashType.FashionForward);

	const inbound = product.orderDetails?.find((r) => r.type === ProductOrderType.Inbound);
	const delivered = product.orderDetails?.find((r) => r.type === ProductOrderType.RecentlyDelivered);

	if (product.id) {
		quantityInBasket = familyQuantities?.[product.id] ?? 0;
	}
	return { quantityInBasket, inbound, delivered, fashionForward };
};

const useProductCardContextState = ({
	product,
	isSkeleton = product === undefined,
	isHidden = false,
	showRemoveIcon = false,
	hideFavIcon = false,
	renderedOnFavouriteList = false,
	className,
	updateNavParams = () => {},
}: ProductCardContextContextProps): UseProductCardContextReturnType => {
	const key = React.useRef(uniqueId(`product-card-`)).current;
	const sanitizedProduct = product ?? {
		brand: '',
		id: '',
		favouriteListCases: [],
		isFavourited: null,
		masters: [],
		name: '',
		pricingDetails: {
			currencyCode: '',
			invariantPrice: '',
			price: '',
			discount: '',
			markup: '',
			recommendedRetailPrice: '',
		},
		orderDetails: [],
		primaryImage: {
			size100: '',
			size450: '',
			size1800: '',
		},
		splashes: [],
		primaryColour: { name: '', hexCode: '' },
		styleNumber: '',
	};
	const [favouriteCases, setFavouriteCases] = useState<FavouriteListLineProductKeyModel[]>(
		sanitizedProduct?.favouriteListCases ?? [],
	);

	let initialMaster = sanitizedProduct.masters?.[0];

	if (renderedOnFavouriteList && !isEmpty(favouriteCases?.[0])) {
		const newMaster = sanitizedProduct.masters?.find((master) => master.id === favouriteCases[0].masterId);

		initialMaster = newMaster ?? initialMaster;
	}

	const [activeMaster, setActiveMaster] = useState<ProductMasterItemResponse | undefined>(initialMaster);
	const [isBeingHovered, setIsBeingHovered] = useState<boolean>(false);
	const bottomSplashes = useGetProductCardBottomSplashes(sanitizedProduct);

	const sanitizedMaster = sanitizedProduct.masters?.find((master) => master.id === activeMaster?.id) ?? initialMaster;

	useEffect(() => {
		if (!isEmpty(initialMaster)) {
			setActiveMaster(initialMaster);
		}
	}, [initialMaster]);

	useEffect(() => {
		const isNewMaster = !isEqual(activeMaster, sanitizedMaster);
		if (isNewMaster) {
			setActiveMaster(sanitizedMaster);
		}
	}, [sanitizedMaster, activeMaster]);

	return {
		key,
		product: sanitizedProduct,
		activeMaster,
		setActiveMaster,
		isSkeleton,
		isHidden,
		favouriteCases,
		setFavouriteCases,
		bottomSplashes,
		showRemoveIcon,
		hideFavIcon,
		renderedOnFavouriteList,
		isBeingHovered,
		className,
		setIsBeingHovered,
		updateNavParams,
	};
};

const ProductCardContextContext = React.createContext<UseProductCardContextReturnType | null>(null);

export const ProductCardContextProvider: React.FunctionComponent<PropsWithChildren<ProductCardContextContextProps>> = ({
	children,
	...props
}: PropsWithChildren<ProductCardContextContextProps>) => {
	const value = useProductCardContextState(props);

	return <ProductCardContextContext.Provider value={value}>{children}</ProductCardContextContext.Provider>;
};

export const useProductCardContext = (): UseProductCardContextReturnType => {
	const context = React.useContext(ProductCardContextContext);

	if (!context) {
		throw new Error('useProductCardContext must be used within a ProductCardContextProvider');
	}

	return context;
};
