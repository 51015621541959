/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AbsoluteUrlResponse,
  AddToFavouriteListRequest,
  BadRequestObjectResult,
  BasketId,
  FavouriteInitPageRequest,
  FavouriteListCopyResponse,
  FavouriteListCreateResponse,
  FavouriteListHashId,
  FavouriteListInitialResponse,
  FavouriteListMediaResponse,
  FavouriteListPageRequest,
  FavouriteListPagedResponse,
  FavouriteListResponse,
  NoPresentationBrandResult,
  ProblemDetails,
  RemoveFromFavouriteListRequest,
  SearchAllRequest,
  SegmentationId,
  SetFavouriteListMediaUrlRequest,
  UpdateFavouriteListDescriptionRequest,
  UpdateFavouriteListNameRequest,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class FavouriteList<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags FavouriteList
   * @name FavouritelistIndexCreate
   * @request POST:/api/favouritelist/index
   * @secure
   */
  favouritelistIndexCreate = (data: FavouriteInitPageRequest, params: RequestParams = {}) =>
    this.request<FavouriteListInitialResponse, ProblemDetails | void>({
      path: `/api/favouritelist/index`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags FavouriteList
   * @name FavouritelistPageCreate
   * @request POST:/api/favouritelist/page
   * @secure
   */
  favouritelistPageCreate = (data: FavouriteListPageRequest, params: RequestParams = {}) =>
    this.request<FavouriteListPagedResponse, ProblemDetails | void>({
      path: `/api/favouritelist/page`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags FavouriteList
   * @name FavouritelistListList
   * @summary List favourites
   * @request GET:/api/favouritelist/list
   * @secure
   */
  favouritelistListList = (params: RequestParams = {}) =>
    this.request<FavouriteListResponse, ProblemDetails | void>({
      path: `/api/favouritelist/list`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags FavouriteList
   * @name FavouritelistMiniList
   * @summary Mini favourite list
   * @request GET:/api/favouritelist/mini
   * @secure
   */
  favouritelistMiniList = (
    query?: {
      segmentationId?: SegmentationId;
    },
    params: RequestParams = {},
  ) =>
    this.request<BasketId, void>({
      path: `/api/favouritelist/mini`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags FavouriteList
   * @name FavouritelistSetactiveCreate
   * @summary Select active favourite list
   * @request POST:/api/favouritelist/setactive/{favouriteListHashId}
   * @secure
   */
  favouritelistSetactiveCreate = (favouriteListHashId: FavouriteListHashId, params: RequestParams = {}) =>
    this.request<AbsoluteUrlResponse, ProblemDetails | void>({
      path: `/api/favouritelist/setactive/${favouriteListHashId}`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags FavouriteList
   * @name FavouritelistSetnameCreate
   * @summary Set name for favourite list
   * @request POST:/api/favouritelist/setname
   * @secure
   */
  favouritelistSetnameCreate = (
    data: UpdateFavouriteListNameRequest,
    query?: {
      favouriteListHashId?: FavouriteListHashId;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, ProblemDetails | void>({
      path: `/api/favouritelist/setname`,
      method: "POST",
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags FavouriteList
   * @name FavouritelistSetdescriptionCreate
   * @summary Set description for favourite list
   * @request POST:/api/favouritelist/setdescription
   * @secure
   */
  favouritelistSetdescriptionCreate = (data: UpdateFavouriteListDescriptionRequest, params: RequestParams = {}) =>
    this.request<void, ProblemDetails | void>({
      path: `/api/favouritelist/setdescription`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags FavouriteList
   * @name FavouritelistSetmediaurlCreate
   * @summary Set media url for favourite list
   * @request POST:/api/favouritelist/setmediaurl
   * @secure
   */
  favouritelistSetmediaurlCreate = (data: SetFavouriteListMediaUrlRequest, params: RequestParams = {}) =>
    this.request<void, ProblemDetails | void>({
      path: `/api/favouritelist/setmediaurl`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags FavouriteList
   * @name FavouritelistAddCreate
   * @summary Add product to favourite list
   * @request POST:/api/favouritelist/add
   * @secure
   */
  favouritelistAddCreate = (data: AddToFavouriteListRequest, params: RequestParams = {}) =>
    this.request<void, ProblemDetails | void>({
      path: `/api/favouritelist/add`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags FavouriteList
   * @name FavouritelistAddalltofavouritelistCreate
   * @summary Adds all the products on PLP to current favourite list
   * @request POST:/api/favouritelist/addalltofavouritelist
   * @secure
   */
  favouritelistAddalltofavouritelistCreate = (data: SearchAllRequest, params: RequestParams = {}) =>
    this.request<void, ProblemDetails | void | BadRequestObjectResult>({
      path: `/api/favouritelist/addalltofavouritelist`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags FavouriteList
   * @name FavouritelistRemoveCreate
   * @summary Remove product from favourite list
   * @request POST:/api/favouritelist/remove
   * @secure
   */
  favouritelistRemoveCreate = (data: RemoveFromFavouriteListRequest, params: RequestParams = {}) =>
    this.request<void, ProblemDetails | void>({
      path: `/api/favouritelist/remove`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags FavouriteList
   * @name FavouritelistCreateCreate
   * @summary Create favourite lists
   * @request POST:/api/favouritelist/create
   * @secure
   */
  favouritelistCreateCreate = (params: RequestParams = {}) =>
    this.request<FavouriteListCreateResponse, NoPresentationBrandResult | ProblemDetails | void>({
      path: `/api/favouritelist/create`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags FavouriteList
   * @name FavouritelistDeleteCreate
   * @summary Delete favourite lists
   * @request POST:/api/favouritelist/delete
   * @secure
   */
  favouritelistDeleteCreate = (data: FavouriteListHashId[], params: RequestParams = {}) =>
    this.request<void, ProblemDetails | void>({
      path: `/api/favouritelist/delete`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags FavouriteList
   * @name FavouritelistCopyCreate
   * @request POST:/api/favouritelist/copy
   * @secure
   */
  favouritelistCopyCreate = (data: FavouriteListHashId, params: RequestParams = {}) =>
    this.request<FavouriteListCopyResponse, ProblemDetails | void>({
      path: `/api/favouritelist/copy`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags FavouriteList
   * @name FavouritelistRemovemediaCreate
   * @request POST:/api/favouritelist/removemedia
   * @secure
   */
  favouritelistRemovemediaCreate = (data: FavouriteListHashId, params: RequestParams = {}) =>
    this.request<FavouriteListHashId, ProblemDetails | void>({
      path: `/api/favouritelist/removemedia`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags FavouriteList
   * @name FavouritelistUploadimageCreate
   * @summary Attaches image to favourite list.
   * @request POST:/api/favouritelist/uploadimage
   * @secure
   */
  favouritelistUploadimageCreate = (
    query: {
      favouriteListHashId: FavouriteListHashId;
    },
    data: {
      /** @format binary */
      File: File;
    },
    params: RequestParams = {},
  ) =>
    this.request<FavouriteListMediaResponse, ProblemDetails | void>({
      path: `/api/favouritelist/uploadimage`,
      method: "POST",
      query: query,
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
}
