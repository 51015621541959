import { convertMasterIdToStyleOptionId } from 'components/features/ProductDetail/productDetailUtils';
import { AssortmentType } from 'generated/data-contracts';

export const SELECT_VIEW_SEARCH_PARAM = 'selectedView';
export const ACTIVE_DATE_SEARCH_PARAM = 'deliveryDate';
export const STYLE_OPTION_NUMBER_SEARCH_PARAM = 'masterId';
export const BUNDLE_ID_SEARCH_PARAM = 'bundleId';

interface FormatProductUrlParams {
	searchParams?: URLSearchParams;
	selectedView?: AssortmentType;
	deliveryDate?: string;
	styleOptionNumber?: string;
	bundleId?: string;
}

export const formatProductUrlParams = ({
	searchParams = new URLSearchParams(),
	selectedView,
	deliveryDate,
	styleOptionNumber,
	bundleId,
}: FormatProductUrlParams) => {
	if (selectedView) {
		searchParams.set(SELECT_VIEW_SEARCH_PARAM, selectedView);
	} else {
		const currentView = searchParams.get(SELECT_VIEW_SEARCH_PARAM);
		if (currentView) {
			searchParams.set(
				SELECT_VIEW_SEARCH_PARAM,
				currentView.toLowerCase() === AssortmentType.FreeAssortments.toLowerCase()
					? AssortmentType.FreeAssortments
					: AssortmentType.Boxes,
			);
		}
	}
	if (deliveryDate) {
		searchParams.set(ACTIVE_DATE_SEARCH_PARAM, deliveryDate);
	}
	if (styleOptionNumber) {
		searchParams.set(STYLE_OPTION_NUMBER_SEARCH_PARAM, convertMasterIdToStyleOptionId(styleOptionNumber));
	}
	if (bundleId) {
		searchParams.set(BUNDLE_ID_SEARCH_PARAM, bundleId);
	}
	return searchParams;
};
