import React from 'react';
import { TooltipContext } from './TooltipContext';
import { TooltipReturnType } from './useTooltip';

export const useTooltipState = (): TooltipReturnType => {
	const context = React.useContext(TooltipContext);

	if (context == null) {
		throw new Error('Tooltip components must be wrapped in <Tooltip />');
	}

	return context;
};
