import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLayoutQuery } from 'api/layout';
import { useTranslationQuery } from 'api/translations';
import { OpenGraphMetadata, PageMetadata } from 'generated/data-contracts';

interface ExtendedOpenGraphProps extends Partial<OpenGraphMetadata> {
	priceCurrency?: string;
	priceAmount?: string;
}

interface HeadProps {
	children?: React.ReactNode;
	metaData: PageMetadata;
	openGraph?: ExtendedOpenGraphProps;
}

const Head: React.FunctionComponent<HeadProps> = ({ children, metaData, openGraph }) => {
	const { data: translations } = useTranslationQuery();
	const { data: layout } = useLayoutQuery();
	const { title, description, keywords, canonicalLink, noIndex, noFollow } = metaData;

	const language = layout?.cultureCode.substring(0, 2) || 'en';

	const scaleToMetaViewport = (): void => {
		const element = document.querySelector('meta[name=viewport]');

		if (element !== null) {
			let content = element.getAttribute('content') || '';
			const regex = /maxium-scale=[0-9.]+ /g;

			if (regex.test(content)) {
				content = content?.replace(regex, 'maxium-scale=1.0');
			} else {
				content = [content, 'maximum-scale=1.0'].join(', ');
			}

			element.setAttribute('content', content);
		}
	};

	const isIOS = (): boolean =>
		['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
			navigator.platform,
		) ||
		(navigator.userAgent.includes('Mac') && 'ontouchend' in document);

	if (isIOS()) {
		scaleToMetaViewport();
	}

	const openGraphData: ExtendedOpenGraphProps = {
		title: openGraph?.title || metaData.openGraph?.title || title,
		description: openGraph?.description || metaData.openGraph?.description || description,
		type: openGraph?.type || 'website',
		image: openGraph?.image,
		url: openGraph?.url || canonicalLink,
		priceCurrency: openGraph?.priceCurrency,
		priceAmount: openGraph?.priceAmount,
	};

	const FULL_WEB_URL_WITHOUT_PROTOCOL = `${location.hostname}${location.pathname}${location.search}`;
	const FULL_WEB_URL_WITH_PROTOCOL = `${location.protocol}//${FULL_WEB_URL_WITHOUT_PROTOCOL}`;

	return (
		<Helmet
			htmlAttributes={{
				lang: language,
			}}
		>
			<title>{metaData.openGraph?.title || title}</title>
			{description && <meta name="description" content={description} />}
			{keywords && <meta name="keywords" content={keywords} />}
			<link rel="icon" type="image/png" href="/dist/favicons/favicon-32x32.png" />
			{canonicalLink && <link rel="canonical" href={canonicalLink} />}
			{(noIndex || noFollow) && (
				<meta name="robots" content={(noIndex && 'noindex' + noFollow && 'nofollow') || undefined} />
			)}
			{!noIndex && !canonicalLink && (
				<meta name="robots" content="max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
			)}
			<meta property="og:site_name" content={translations?.shared.siteName} />
			{openGraphData.title && <meta property="og:title" content={openGraphData.title} />}
			{openGraphData.description && <meta property="og:description" content={openGraphData.description} />}
			{openGraphData.image && <meta property="og:image" content={openGraphData.image} />}
			{openGraphData.type && <meta property="og:type" content={openGraphData.type} />}
			{openGraphData.url && <meta property="og:url" content={openGraphData.url} />}
			{openGraphData.priceCurrency && <meta property="og:price:currency" content={openGraphData.priceCurrency} />}
			{openGraphData.priceAmount && <meta property="og:price:amount" content={openGraphData.priceAmount} />}
			<meta property="al:ios:url" content={`bestsellermore://${FULL_WEB_URL_WITHOUT_PROTOCOL}`} />
			<meta property="al:ios:app_store_id" content="6468228231" />
			<meta property="al:ios:app_name" content="BESTSELLER - MORE" />
			<meta property="al:android:url" content={`bestsellermore://${FULL_WEB_URL_WITHOUT_PROTOCOL}`} />
			<meta property="al:android:app_name" content="BESTSELLER - MORE" />
			<meta property="al:android:package" content="com.bestseller.bestseller.more.prd" />
			<meta property="al:web:url" content={FULL_WEB_URL_WITH_PROTOCOL} />
			{children}
		</Helmet>
	);
};

export default Head;
