import React from 'react';
import {
	autoUpdate,
	flip,
	offset as calculateOffset,
	shift,
	useDismiss,
	useFloating,
	useFocus,
	useHover,
	useInteractions,
	useRole,
} from '@floating-ui/react-dom-interactions';
import { TooltipOptions } from './Tooltip';

export interface TooltipReturnType extends ReturnType<typeof useInteractions>, ReturnType<typeof useFloating> {
	open: boolean;
	showArrow: boolean;
	setOpen: (open: boolean) => void;
	isRenderedInPortal: boolean;
}

/* https://floating-ui.com/docs/react-dom-interactions*/
export function useTooltip({
	delay = 80,
	initialOpen = false,
	offset = 10,
	onOpenChange: setControlledOpen,
	open: controlledOpen,
	placement = 'right',
	showArrow = false,
	strategy = 'fixed',
	enabled = true,
	isRenderedInPortal = true,
}: TooltipOptions = {}): TooltipReturnType {
	const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);

	const open = controlledOpen ?? uncontrolledOpen;
	const setOpen = setControlledOpen ?? setUncontrolledOpen;

	const data = useFloating({
		placement,
		open,
		onOpenChange: setOpen,
		whileElementsMounted: autoUpdate,
		middleware: [calculateOffset(offset), flip(), shift()],
		strategy,
	});
	const context = data.context;

	const hover = useHover(context, {
		move: false,
		enabled: controlledOpen == null && enabled,
		delay,
	});
	const focus = useFocus(context, {
		enabled: controlledOpen == null && enabled,
	});
	const dismiss = useDismiss(context);
	const role = useRole(context, { role: 'tooltip' });
	const interactions = useInteractions([hover, focus, dismiss, role]);

	return React.useMemo<TooltipReturnType>(
		() => ({
			open,
			showArrow,
			setOpen,
			isRenderedInPortal,
			...interactions,
			...data,
		}),
		[open, showArrow, setOpen, isRenderedInPortal, interactions, data],
	);
}
