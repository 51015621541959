import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { mergeRefs, portalRootId } from 'helpers';
import { useTooltipState } from './helpers/useTooltipState';
import styles from './Tooltip.module.scss';

export const TooltipContent = React.forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLElement> & { style?: React.CSSProperties }
>(function TooltipContent({ className, ...props }, propRef) {
	const state = useTooltipState();
	const portalRoot: HTMLElement | null = document.getElementById(portalRootId) || document.body;

	const ref = React.useMemo(() => mergeRefs([state.floating, propRef]), [state.floating, propRef]);
	const Component = () => {
		if (!state.open) return null;
		return (
			<div
				className={classNames(className, styles.tooltipContent, {
					[styles.showArrow]: state.showArrow,
				})}
				ref={ref}
				style={{
					position: state.strategy,
					top: state.y ?? 0,
					left: state.x ?? 0,
					visibility: state.x == null ? 'hidden' : 'visible',
					...props.style,
				}}
			>
				<aside {...state.getFloatingProps(props)} />
			</div>
		);
	};
	if (state.isRenderedInPortal) {
		return ReactDOM.createPortal(<Component />, portalRoot);
	}
	return <Component />;
});
